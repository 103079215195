'use client'

import React, { useEffect, useState } from 'react'
import Projects from '@/components/tabs/projects'
import ModalForProject from '@/components/ModalForProject'
import { useQueryTC, useMutationTC } from '@/hooks/react-query'
import { Organization, Project } from '@prisma/client'
import { LoadingIndicator } from '@/components/LoadingIndicator'
import { useOrganization, useOrganizations } from '@/hooks/req'
import { usePathname, useRouter } from 'next/navigation'

import { PageHeader } from '@/components/PageHeader'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import Link from 'next/link'
import { Avatar } from '@/components/Avatar'
import Button from '@/components/Button'
import { Card, CardHeader, CardFooter } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import {
  EllipsisHorizontalIcon,
  Cog8ToothIcon,
  PlusIcon,
  ExclamationTriangleIcon,
  ArrowRightIcon,
} from '@heroicons/react/20/solid'
import { ProjectForDashboard } from '@/models'
import NoProjects from '@/components/tabs/projects/NoProjects'
import { Separator } from '@/components/ui/separator'
import { useQueryClient } from '@tanstack/react-query'

export default function Page({
  params,
}: {
  params: { organizationSlug: string }
}) {
  const { organizationSlug } = params
  const slug = organizationSlug

  const router = useRouter()
  const queryClient = useQueryClient()

  const { organization, refetch } = useOrganization(organizationSlug)
  const { organizations } = useOrganizations()

  const m = useMutationTC<Partial<Project>>('project')
  const [isProjectModalOpen, setProjectModalOpen] = useState(false)

  const handleCreateProjectCallback = async (
    name: string,
    organizationId: string
  ) => {
    const p = await m.mutateAsync({ name, organizationId })
    // We full page reload in case other parts of the screen are showing a list of projects.
    // This is common on the main org settings page.
    queryClient.invalidateQueries()
    router.push(`/${organizationSlug}/${p.slug}`)
  }

  if (!organization) {
    return <LoadingIndicator isScreenCentered />
  }

  const projects = organization?.projects
  return (
    <>
      <ModalForProject
        organization={organization}
        organizations={organizations}
        open={isProjectModalOpen}
        setOpen={setProjectModalOpen}
        handleCallback={handleCreateProjectCallback}
      />
      <div className="flex flex-col w-full max-w-[1024px] justify-between gap-4 md:gap-8">
        <PageHeader
          title="Projects"
          buttonTitle={'New Project'}
          buttonOnClick={() => setProjectModalOpen(true)}
        />
        {projects && projects.length === 0 ? (
          <NoProjects onCreateProject={() => setProjectModalOpen(true)} />
        ) : (
          <></>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4 md:px-8">
          {!projects &&
            Array(12)
              .fill(0)
              .map((_, i) => <Skeleton key={i} className="w-full h-28" />)}
          {projects?.map((p) => (
            <Card
              key={p.id}
              className="w-full border-gray-200 border p-4"
              onClick={() => router.push(p.path)}
            >
              <CardHeader>
                <div className="flex justify-between gap-4 md:gap-8">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4">
                      <Avatar className="w-10 h-10" project={p} />
                      <div className="flex max-w-[80%] flex-col">
                        <div className="text-medium font-bold whitespace-nowrap">
                          {p.name}
                        </div>
                        {p.requiresSetup && (
                          <div className="flex items-center gap-1 text-yellow-500">
                            <div className="pt-0.5 text-xs">
                              <ExclamationTriangleIcon className="w-5 h-5" />
                            </div>
                            <div className="font-normal whitespace-nowrap text-sm">
                              No events received yet.
                            </div>
                          </div>
                        )}
                        {!p.requiresSetup && (
                          <div className="font-normal whitespace-nowrap text-sm text-gray-500">
                            {p.description || 'No description'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button variant="light" isIconOnly>
                        <EllipsisHorizontalIcon className="w-5 h-5 text-gray-500" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="flex flex-col p-2">
                      <Link href={p.path + '/settings'}>
                        <DropdownMenuItem className="sm:w-56 w-48 flex items-center gap-1.5">
                          <Cog8ToothIcon className="w-5 h-5 text-gray-600" />
                          Settings
                        </DropdownMenuItem>
                      </Link>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </CardHeader>
              <CardFooter>
                <div className="w-full flex flex-col gap-4">
                  <Separator />
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex flex-row gap-4">
                      <div className="flex max-w-[80%] flex-col">
                        <div className="whitespace-nowrap text-gray-500 text-xs">
                          Weekly Events
                        </div>
                        <div className="font-bold text-lg whitespace-nowrap">
                          {p.counts.events}
                        </div>
                      </div>
                      <div className="flex max-w-[80%] flex-col">
                        <div className="whitespace-nowrap text-gray-500 text-xs">
                          Weekly Errors
                        </div>
                        <div className="font-bold text-lg whitespace-nowrap">
                          {p.counts.errors}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2">
                      <Link href={`${p.path}`}>
                        <Button isIconOnly variant="ghost">
                          <ArrowRightIcon className="w-5 h-5" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </CardFooter>
            </Card>
          ))}
          {projects && projects.length > 0 && (
            <Card
              className="w-full border-gray-200 border relative hover:bg-gray-50 hover:cursor-pointer"
              onClick={() => setProjectModalOpen(true)}
            >
              <div className="absolute w-full h-full flex items-center justify-center gap-4">
                <div className="w-8 h-8 rounded-full outline-double outline-1 flex items-center justify-center outline-gray-300 bg-gray-100">
                  <PlusIcon className="w-5 h-5 text-gray-500" />
                </div>
                <div className="font-bold text-lg">New Project</div>
              </div>
              <CardHeader className="opacity-0">
                <div className="flex justify-between gap-4 md:gap-8">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Avatar className="w-10 h-10" src="" />
                      <div className="flex max-w-[80%] flex-col">
                        <div className="text-medium font-bold whitespace-nowrap">
                          test
                        </div>
                        <div className="flex items-center gap-1 text-yellow-500">
                          <div className="pt-0.5 text-xs">
                            <ExclamationTriangleIcon className="w-5 h-5" />
                          </div>
                          <div className="font-normal whitespace-nowrap text-sm">
                            No events received yet.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardFooter className="opacity-0">
                <div className="w-full flex flex-col gap-4">
                  <Separator />
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex flex-row gap-4">
                      <div className="flex max-w-[80%] flex-col">
                        <div className="whitespace-nowrap text-gray-500 text-xs">
                          Weekly Events
                        </div>
                        <div className="font-bold text-lg whitespace-nowrap">
                          {'2,567'}
                        </div>
                      </div>
                      <div className="flex max-w-[80%] flex-col">
                        <div className="whitespace-nowrap text-gray-500 text-xs">
                          Weekly Errors
                        </div>
                        <div className="font-bold text-lg whitespace-nowrap">
                          {'1,532'}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2">
                      <Button isIconOnly>
                        <ArrowRightIcon className="w-5 h-5" />
                      </Button>
                    </div>
                  </div>
                </div>
              </CardFooter>
            </Card>
          )}
        </div>
      </div>
    </>
  )
}
