import React, { useEffect, useState } from 'react'
import { WindowIcon } from '@heroicons/react/24/outline'
import ModalBase, { formHelper } from './ModalBase'
import { Organization, User } from '@prisma/client'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Select } from '@/components/ui/select'
import type { Item } from '@/components/ui/select'
import { usePathname } from 'next/navigation'

type Props = {
  user: User
  organization: Organization
  organizations: Organization[]
  open: boolean
  setOpen: (open: boolean) => void
  handleCallback: (name: string, organizationId: string) => void
}

export default function ModalForProject({
  organizations,
  organization,
  open,
  setOpen,
  handleCallback,
}: Props) {
  const pathname = usePathname()
  const formSchema = z.object({
    organizationId: z.string().min(2).max(50),
    projectName: z.string().min(2).max(50),
  })
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      organizationId: organization?.id || '',
      projectName: '',
    },
    values: {
      organizationId: organization?.id || '',
      projectName: '',
    },
  })
  const [defaultOrganization, setDefaultOrganization] = useState<Item>()
  useEffect(() => {
    const [_, slug, p] = pathname.split('/')
    if (organizations && organizations.length > 0) {
      const o = organizations.find((o) => o.slug === slug)
      setDefaultOrganization(o)
    }
  }, [pathname, organizations])
  function onSubmit(values: z.infer<typeof formSchema>) {
    handleCallback(values.projectName, values.organizationId)
  }
  if (!organizations || !defaultOrganization) {
    return null
  }
  return (
    <ModalBase
      icon={<WindowIcon className="h-6 w-6 text-primary" aria-hidden="true" />}
      title={'Create a New Project'}
      body={'Start analyzing another app by creating a new Project.'}
      open={open}
      setOpen={setOpen}
      handleSuccess={formHelper(form, onSubmit)}
      successText="New Project"
    >
      <Form {...form}>
        <form className="flex flex-col gap-3">
          <FormField
            control={form.control}
            name="organizationId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Organization</FormLabel>
                <Select
                  isSearchable={true}
                  items={organizations}
                  onChange={field.onChange}
                  defaultItem={defaultOrganization}
                  placeholder="Organization"
                />
                <FormDescription>
                  Organizations segment users, projects, and billing.
                  <FormMessage />
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="projectName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Project Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter project name" {...field} />
                </FormControl>
                <FormDescription>
                  You can always change the Project name later.
                  <FormMessage />
                </FormDescription>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </ModalBase>
  )
}
