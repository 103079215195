import React from 'react'
import Button from '@/components/Button'
import { FolderOpenIcon } from '@heroicons/react/20/solid'

type Props = {
  onCreateProject: () => void // Define the type for the callback prop
}

export default function NoProjects({ onCreateProject }: Props) {
  return (
    <div className="flex items-center justify-center py-20 text-textBody">
      <div className="flex flex-col items-center justify-center w-full max-w-md text-center">
        <div className="flex justify-center mb-4">
          <FolderOpenIcon className="h-12 w-12 text-muted-foreground" />
        </div>
        <h2 className="text-2xl font-bold mb-2">No Projects found</h2>
        <div className="text-muted-foreground mb-4">
          A Project is a collection of events and errors.
        </div>
        <Button variant="bordered" onClick={onCreateProject}>
          Create New Project
        </Button>{' '}
      </div>
    </div>
  )
}
