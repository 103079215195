import React from 'react'
import Button from '@/components/Button'
import { PlusIcon, InformationCircleIcon } from '@heroicons/react/24/solid'

type Props = {
  title: React.ReactNode | string
  subtitle?: React.ReactNode | string
  buttonTitle?: string
  secondButtonTitle?: string
  hasBackButton?: boolean
  buttonOnClick?: () => void
  secondButtonOnClick?: () => void
}

export const PageHeader = ({
  title,
  subtitle,
  buttonTitle,
  buttonOnClick,
  secondButtonTitle,
  secondButtonOnClick,
  hasBackButton = false,
}: Props) => {
  return (
    <div className="flex items-center justify-between space-x-4 w-full border-b border-gray-200 px-4 md:px-8">
      <div className="flex flex-col py-4 md:py-8 gap-1">
        <h1 className="text-2xl font-medium leading-9 tracking-tight text-gray-900 sm:text-2xl md:text-3xl">
          {title}
        </h1>
        {subtitle && <div className="text-md text-gray-500">{subtitle}</div>}
      </div>
      <div className="flex gap-2">
        <div className="flex space-x-3 text-white">
          {buttonTitle && buttonOnClick && buttonTitle.length > 0 && (
            <Button
              variant="ghost"
              isSecond
              className="whitespace-nowrap"
              startContent={<PlusIcon className="text-gray-950 w-4 h-4" />}
              onClick={buttonOnClick}
            >
              {buttonTitle}
            </Button>
          )}
        </div>
        {secondButtonTitle && secondButtonOnClick && (
          <div className="flex space-x-3 text-white">
            {secondButtonTitle &&
              secondButtonOnClick &&
              secondButtonTitle.length > 0 && (
                <Button
                  variant="ghost"
                  className="whitespace-nowrap"
                  startContent={<PlusIcon className="text-gray-950 w-4 h-4" />}
                  onClick={secondButtonOnClick}
                >
                  {secondButtonTitle}
                </Button>
              )}
          </div>
        )}
      </div>
    </div>
  )
}
